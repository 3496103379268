@import url(https://fonts.googleapis.com/css?family=Lato:700);
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
:root {
	--BTNColor: #975399;
}

.body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
	min-width: 500px;
	overflow: scroll;
}

.Banner {
	height: 16rem;
	width: 100%;
	background: url("../images/HypeCodePlaygroundwoBG.png") no-repeat,
		linear-gradient(to bottom, #30cfd0 0%, #330867 100%);
	background-size: contain;
	background-position: center center;
	margin-left: 2px;
}

.app-container {
	position: absolute;
	top: 21.5rem;
	bottom: 0;
	left: 0;
	right: 0;
	height: 42rem;
	overflow: visible;
	background-color: #0097a7;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
	margin-top: 8px;
	margin-left: 10px;
	margin-right: 6px;
	margin-bottom: 10px;
}

.main {
	position: absolute;
	top: 8px;
	bottom: 8px;
}

.blockly-panel {
	left: 8px;
	right: 35%;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.37);
}

.output-panel {
	right: 8px;
	width: 32%;
	background: white url("../images/javascriptLogo.svg") no-repeat;
	background-size: contain;
	background-position: center center;
	overflow: scroll;
	padding: 8px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.37);
}

#buttonBar {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 4fr 1fr 1fr 1fr;
	grid-gap: 5px;
	place-items: center;
	background-color: #0097a7;
	height: 4.5rem;
	width: 100%;
	top: 16rem;
	margin-left: 2px;
	margin-right: 6px;
	padding-top: 8px;
	padding-bottom: 12px;
}

.btnBar-button {
	position: relative;
	border-radius: 100%;
	color: white;
	width: 50px;
	height: 50px;
	line-height: 50px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.37);
	transition: box-shadow 0.3s ease-in-out;
	text-align: center;
	font-weight: 500;
	background-color: var(--BTNColor);
	cursor: pointer;
	font-size: 32px;
}

.btnBar-button:hover {
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.3);
	transition: box-shadow 0.3s ease-in-out;
}

.but-not-that-pretty {
	border: 0 !important;
	margin: 0 !important;
	padding: 0 !important;
}

#saveBlocksButton {
	background: url(../images/blocksUpIcon.svg) var(--BTNColor) no-repeat;
	background-size: contain;
	background-position: center center;
}

#clearBlockSpace {
	background: url(../images/ClearBlockSpaceIcon.svg) var(--BTNColor) no-repeat;
	background-size: contain;
	background-position: center center;
}

#restoreBlocksButton {
	background: url(../images/blocksDownIcon.svg) var(--BTNColor) no-repeat;
	background-size: contain;
	background-position: center center;
	width: 50px;
	height: 50px;
}
.exampleSelectorControl {
	width: 300px;
	color: white;
	font-size: 14px;
	font-family: sans-serif;
	border-color: white;
	border-width: 4px;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
	border-radius: 8px;
}

legend {
	background-color: var(--BTNColor);
	color: #fff;
	font-family: verdana, sans-serif;
	padding: 4px;
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
}

.select-css {
	display: block;
	font-size: 14px;
	font-family: verdana, sans-serif;
	font-weight: 700;
	color: var(--BTNColor);
	line-height: 1.1;
	padding: 0.6em 1.4em 0.5em 0.8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
	border-radius: 0.5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
		linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right 0.7em top 50%, 0 0;
	background-size: 0.65em auto, 100%;
}
.select-css::-ms-expand {
	display: none;
}
.select-css:hover {
	border-color: #888;
}
.select-css:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
.select-css option {
	font-weight: normal;
}

.videoContainer {
	position: absolute;
	top: 64rem;
	bottom: 0;
	left: 0;
	right: 0;
	height: 22rem;
	width: 99vw;
	background-color: black;
	margin-left: 10px;
	margin-right: 2px;
}

#videoContainerTitle {
	color: beige;
	padding-left: 40px;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.Footer {
	position: absolute;
	top: 86rem;
	bottom: 0;
	left: 0;
	right: 0;
	height: 15rem;
	width: 99vw;
	background: url("../images/Playground_footer.png") no-repeat,
		linear-gradient(to top, #30cfd0 0%, #330867 100%);
	background-size: contain;
	background-position: center center;
	margin-left: 10px;
	margin-right: 8px;
}

.contact_Container {
	position: absolute;
	top: 13rem;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 26px;
}

.shepherd-element {
	background: #975399;
}

.shepherd-arrow,
.shepherd-arrow:before {
	position: absolute;
	width: 16px;
	height: 16px;
	z-index: -1;
}
.shepherd-arrow:before {
	content: "";
	transform: rotate(45deg);
	background: #975399;
}

.shepherd-text {
	color: white;
	font-family: Verdana, sans-serif;
}

.shepherd-title {
	color: #975399;
	font-size: larger;
	font-family: Verdana, sans-serif;
}

.shepherd-button {
	background: #0097a7;
}

.shepherd-cancel-icon {
	background: transparent;
	border: none;
	color: #975399;
	font-size: 2em;
	cursor: pointer;
	font-weight: 400;
	margin: 0;
	padding: 0;
}

.shepherd-cancel-icon:hover {
	color: #975399;
}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
	color: #975399;
}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
	color: #975399;
}

/* Ribbon Banners */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 14px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
  display: none;
}
